/* eslint-disable no-undef */
import React, { Component } from 'react';
import { message, Table } from 'antd';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import html2canvas from 'html2canvas';
import PostInfoModal from './postInfoModal';
import { getOrder } from '../../services/order';
import { searchConvertObj } from '../../utils/utilHelper';
import { getConfig } from '../../config/department';
import { baseUrl } from '../../config/baseurl';
import copyImg from '../../resource/img/copy.png';
import recordIcon from '../../resource/img/recordIcon.png';
import landmark from '../../resource/img/landmark.png';
import borders from '../../resource/img/0.jpg';
import forty from '../../resource/img/404.jpg';
import unpaid from '../../resource/img/unpaid.png';
import paid from '../../resource/img/paid.png';
import machining from '../../resource/img/machining.png';
import deliver from '../../resource/img/deliver.png';
import receiving from '../../resource/img/receiving.png';
import intention from '../../resource/img/intention.png';
import backgrund from '../../resource/img/backgrund.png';
import styles from './order.module.css';
import { cloud, isLj, orderValue } from '../../config/baseurl.js';
// import toExcel from '../../utils/toExcel';

const dateFormat = 'YYYY-MM-DD';
const types = ['布', '纱', '高定布', '高定纱', '半成品'];
const types2 = ['坐垫', '台布', '抱枕', '高定坐垫', '高定台布', '高定抱枕'];

//订单状态
const getOrderState = (orderState) => {
  let type = '';
  switch (orderState) {
    case '未审核':
      type = unpaid;
      break;
    case '已审核':
      type = paid;
      break;
    case '加工中':
      type = machining;
      break;
    case '部分发货':
    case '已发货':
      type = deliver;
      break;
    case '已签收':
      type = receiving;
      break;
    case '意向':
    case '未推送':
    case '未确认':
    default:
      type = intention;
      break;
  }
  return type;
};
//产品名字和图片
const getName = (item) => {
  let pic = '';
  if (item.type === '窗帘') {
    pic = item.component.filter(r => types.includes(r.type))[0].pic;
  } else if (item.type === '杆轨') {
    pic = item.component.filter(r => r.type === '轨道' || r.type === '罗马杆')[0].pic;
  } else if (['窗幔', '功能帘', '布绑', '抱枕', '成品'].includes(item.type)) {
    pic = item.component.filter(r => r.type === item.type || r.type === `高定${item.type}`)[0].pic;
  } else if (item.type === '软垫') {
    pic = item.component.filter(r => types2.includes(r.type))[0].pic;
  } else {
    pic = item.component[0].pic;
  }
  return { pic };
};
//房间
const getRoom = (item, rooms, department) => {
  let tempRooms = rooms.filter(r => r.id === item.room);
  if (tempRooms.length === 0) { return ''; }
  let room = tempRooms[0];
  let name = room.name;
  if (room.width || room.height) {
    let arr = [];
    if (room.width) {
      arr.push(`宽:${room.width}米`);
    }
    if (room.height) {
      arr.push(`高:${room.height}米`);
    }
    if (department !== 80) {
      name = `${name}(${arr.join(',')})`;
    }
  }
  return name;
};

const getMaterial = (item, department = 1) => {
  if (department === 77 || department === 80 || department === 123) {
    let materials = [];
    for (let r of item.material) {
      if (r.type !== '布绑' && r.type !== '高定布绑') {
        materials.push(` ${r.position ? r.position + ':' : ''}${r.name}`);
      }
    }
    return materials;
  } else {
    return item.material.map(r => {
      return r.position ? ` ${r.position}:${r.name}` : r.name;
    }).join('|');
  }

};
//产品信息
const getProduct = (item, department) => {
  const product = {
    '窗帘': function () {
      let { isShowSize, isFolb } = getConfig(department);
      let name = item.component.filter(r => types.includes(r.type))[0].name;
      let material = getMaterial(item, department);
      let materialForm = [];
      if (material.length > 0) {
        if (department === 77 && item.material[0].type.indexOf('高定') !== -1) {
          materialForm = material;
        } else {
          name = `${name}(${material})`;
        }
      }
      let size = '';
      let sizeArr = [];
      if (item.prop1) {
        sizeArr.push(`宽${item.prop1}米`);
      }
      if (item.prop2) {
        sizeArr.push(`高${item.prop2}米`);
      }
      size = sizeArr.join(',');
      let shardArr = item.prop3.split('|');
      let folbOrSize = '';
      if (shardArr.length >= 2) {
        if (isFolb) {
          folbOrSize = `褶皱倍率:${shardArr[1]}`;
        }
        else {
          let productSizeArr = [];
          productSizeArr.push(`宽${shardArr[1]}米`);
          if (item.prop2) {
            productSizeArr.push(`高${item.prop2}米`);
          }
          let productSize = productSizeArr.join(',');
          folbOrSize = `成品尺寸:${productSize}`;
        }
      }
      //褶皱或成品尺寸
      let work = '';
      let workArr = item.component.filter(r => r.type === '加工');
      if (workArr.length > 0) {
        work = workArr[0].name;
      }
      // let complex = item.component.filter(r => r.type === '复杂');
      let complex = item.component.filter(r => r.type === '复杂' || r.type === '衬布' || r.type === '定型');
      return (
        <div>
          <div> {item.type}:{name}</div>
          {materialForm === undefined ? <div /> :
            materialForm.map((item, index) => {
              return (
                <div key={index}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item}</div>
              );
            })
          }
          <div></div>
          <div>{isShowSize && `尺寸:${size}`}</div>
          {/* 来料加工事业部128，自下单事业部122，云居软装1167，布之美1168，吉屋1169，morninghome1170 ,
          欧瑞莎1171，欣明家居1172，王牌印象1173，春之南1174，青庭软装1175 */}
          <div>{department === 123 || department === 122 || department === 128 || department === 1167
            || department === 1168 || department === 1169 || department === 1170 || department === 1171
            || department === 1172 || department === 1173 || department === 1174 || department === 1175
            || department === 1212 || department === 1205
            ? '' : folbOrSize}</div>
          {item.prop4 && <div>面料方向:{item.prop4}</div>}
          <div>加工方式:{work}</div>
          <div>分片:{shardArr[0]}</div>
          {
            complex.length > 0 && department !== 123 &&
            <div className='col-t vertical-lt'>
              <div>
                复杂加工:
              </div>
              <div>
                {
                  complex.map((item, index) => {
                    return (
                      <div key={index}>{index + 1}.{item.name} </div>);
                  })
                }
              </div>
            </div>
          }
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '窗幔': function () {
      let name = item.component.filter(r => r.type === item.type || r.type === `高定${item.type}`)[0].name;
      let material = getMaterial(item);
      if (material.length > 0) {
        name = `${name}(${material})`;
      }
      let size = '';
      let sizeArr = [];
      if (item.prop1) {
        sizeArr.push(`宽:${item.prop1}米`);
      }
      if (item.prop2) {
        sizeArr.push(`高:${item.prop2}米`);
      }
      size = sizeArr.join(',');
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>尺寸:{size}</div>
          <div>固定方式:{item.prop3}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '功能帘': function () {
      let name = item.component.filter(r => r.type === item.type || r.type === `高定${item.type}`)[0].name;
      let material = getMaterial(item);
      if (material.length > 0) {
        name = `${name}(${material})`;
      }
      let size = '';
      let sizeArr = [];
      if (item.prop1) {
        sizeArr.push(`宽:${item.prop1}米`);
      }
      if (item.prop2) {
        sizeArr.push(`高:${item.prop2}米`);
      }
      size = sizeArr.join(',');
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>尺寸:{size}</div>
          {item.prop3 && item.prop3.length === 2 && (<div>拉珠方向:{item.prop3}</div>)}
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '杆轨': function () {
      let name = item.component.filter(r => r.type === '轨道' || r.type === '罗马杆')[0].name;
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>层数:{item.prop1}</div>
          <div>长度:{item.prop2}米</div>
          <div>安装:{item.prop3}</div>
          <div>分段:{item.prop4}</div>
          <div>装饰头:{item.prop5}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '抱枕': function () {
      let name = item.component.filter(r => r.type === item.type || r.type === `高定${item.type}`)[0].name;
      let material = getMaterial(item);
      if (material.length > 0) {
        name = `${name}(${material})`;
      }
      let size = '';
      let sizeArr = [];
      if (item.prop1) {
        sizeArr.push(`宽:${item.prop1}米`);
      }
      if (item.prop2) {
        sizeArr.push(`高:${item.prop2}米`);
      }
      size = sizeArr.join(',');
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>尺寸:{size}</div>
          <div>数量:{item.prop4}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '布绑': function () {
      let name = item.component.filter(r => r.type === item.type || r.type === `高定${item.type}`)[0].name;
      let material = getMaterial(item);
      if (material.length > 0) {
        name = `${name}(${material})`;
      }
      return (
        <div className={'fz14'}>
          <div> {item.type}:{name}</div>
          <div>数量:{item.prop4}个</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '成品': function () {
      let name = item.component[0].name;
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>数量:{item.prop1}{item.prop2}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '样品': function () {
      let name = item.component[0].name;
      return (
        <div className={'fz14'}>
          <div> {item.type}:{name}</div>
          <div>数量:{item.prop1}{item.prop2}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '零剪': function () {
      let type = item.component[0].type;
      let name = item.component[0].name;
      return (
        <div className={'fz14'}>
          <div> {type}:{name}</div>
          <div>数量:{item.prop1}{item.prop2}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '飘窗垫': function () {
      let name = item.component.filter(r => r.type === item.type || r.type === `高定${item.type}`)[0].name;
      let material = getMaterial(item, department);
      name = `${name}(${material})`;
      let size = '';
      let sizeArr = [];
      if (item.prop1) {
        sizeArr.push(`宽:${item.prop1}米`);
      }
      if (item.prop2) {
        sizeArr.push(`高:${item.prop2}米`);
      }
      size = sizeArr.join(',');
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>尺寸:{size}</div>
          <div>数量:{item.prop4}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div >
      );
    },
    '软垫': function () {
      let name = item.component.filter(r => types2.includes(r.type))[0].name;
      let material = getMaterial(item);
      if (material.length > 0) {
        name = `${name}(${material})`;
      }
      let sizeArr = [];
      let size = '';
      if (item.prop1) {
        sizeArr.push(`长:${item.prop1}米`);
      }
      if (item.prop2) {
        sizeArr.push(`宽:${item.prop2}米`);
      }
      if (item.prop3) {
        sizeArr.push(`厚:${item.prop2}米`);
      }
      size = sizeArr.join(',');
      return (
        <div>
          <div> {item.type}:{name}</div>
          <div>尺寸:{size}</div>
          <div>数量:{item.prop4}</div>
          {item.remark && (<div>加工备注:{item.remark}</div>)}
        </div>
      );
    }
  };
  if (typeof product[item.type] !== 'function') {
    return null;
  }
  return product[item.type]();
};

//是否为布帘
const isCurtain = (components) => {
  let result = false;
  // eslint-disable-next-line array-callback-return
  components.map((item, index) => {
    if (item.sort === 1 && item.type === '布') {
      result = true;
    }
  });
  return result;
};

//表格
const getTitle = (data, department) => {
  const push_time = moment(data.push_time).format('YYYY-MM-DD');
  const send_time = moment(data.send_time).format('YYYY-MM-DD');
  const create_time = moment(data.create_time).format('YYYY-MM-DD');

  let finishTime = department === 103 ? '客户：' + data.customer2 : '完成日期：' + send_time;
  return (
    <table style={{ marginTop: '10px' }}>
      <tbody className={styles['cell']}>
        <tr>
          <td className={styles['values']}>订单编号：{data.id}  （创建时间：{create_time}）</td>
          <td className={styles['values']}>下单时间：{push_time}</td>
          <td className={styles['values']}>{finishTime}</td>
          <td className={styles['values']}>客服：{data.department_user}</td>
        </tr>
        <tr>
          <td className={styles['values']}>
            客户名称：{department === 118 ? data.customer1 : data.name}{department === 124 ? data.customer2 ? '/' + data.customer2 : '' : ''}
          </td>
          <td className={styles['values']}>联系方式：{data.phone}</td>
          <td className={styles['values']} colSpan={3}>收货地址：{`${data.province}${data.city}${data.district}${data.address}`}</td>
        </tr>

        {department === 124 && data.posts[0] && data.posts[0].remark &&
          <tr>
            <td className={styles['values']} colSpan={4}>发货备注：{data.posts[0].remark}</td>
          </tr>
        }
        {
          department === 118 &&
          <tr>
            <td className={styles['values']} colSpan={2}>备注信息：{data.customer2}</td>
            <td className={styles['values']} colSpan={2}>付款方式：{data.posts[0].pay} , &nbsp;&nbsp; 物流公司：{data.posts[0].name}</td>
          </tr>
        }
      </tbody>
    </table>

  );
};

const getCurtains = (data, department = 0) => {
  if (!data || data.length === 0) { return []; }
  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    let payBill = data[i].bills.filter(r => r.total_oms !== 0);
    let complexs = '';
    data[i].complex.map(r => {
      complexs = complexs + r + '|';
      return complexs;
    });
    complexs = complexs.slice(0, complexs.length - 1);
    let remark = data[i].remark ? '；  备注：' + data[i].remark : '';
    let count2 = department === 124 && data[i].price !== data[i].priceOriginal ? data[i].price + '（原价:' + data[i].priceOriginal + ')' : data[i].price;
    if (data[i].materials.length > payBill.length) {
      for (let j = 0; j < data[i].materials.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: data[i].materials.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            width: data[i].width,
            height: data[i].height,
            method: data[i].work,
            shard: data[i].piece,
            methodShard: data[i].work + '+' + data[i].piece,
            complex: complexs + remark,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: count2,
          });

        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: data[i].materials.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            width: -1,
            height: -1,
            method: -1,
            shard: -1,
            methodShard: -1,
            complex: -1,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: -1,
          });
        }
      }
    } else {
      for (let j = 0; j < payBill.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: payBill.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            width: data[i].width,
            height: data[i].height,
            method: data[i].work,
            shard: data[i].piece,
            methodShard: data[i].work + '+' + data[i].piece,
            complex: complexs + remark,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: payBill.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            width: -1,
            height: -1,
            method: -1,
            shard: -1,
            methodShard: data[i].work + '+' + data[i].piece,
            complex: -1,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: -1,
          });
        }
      }
    }

  }
  return tableData;
};
const getValances = (data, department = 0) => {
  if (!data || data.length === 0) { return []; }
  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    let payBill = data[i].bills.filter(r => r.total_oms !== 0);
    let count2 = department === 124 && data[i].price !== data[i].priceOriginal ? data[i].price + '（原价:' + data[i].priceOriginal + ')' : data[i].price;
    let complexs = '';
    if (data[i].complex) {
      data[i].complex.map(r => {
        complexs = complexs + r + '|';
        return complexs;
      });
      complexs = complexs.slice(0, complexs.length - 1);
    }
    if (data[i].materials.length > payBill.length) {
      for (let j = 0; j < data[i].materials.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: data[i].materials.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            width: data[i].width,
            height: data[i].height,
            fixed: data[i].fixType,
            complex: data[i].complex ? complexs : '',
            remark: data[i].remark,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: data[i].materials.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            width: -1,
            height: -1,
            fixed: -1,
            complex: -1,
            remark: -1,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: -1,
          });
        }
      }
    } else {
      for (let j = 0; j < payBill.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: payBill.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            width: data[i].width,
            height: data[i].height,
            fixed: data[i].fixType,
            complex: data[i].complex ? complexs : '',
            remark: data[i].remark,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: payBill.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            width: -1,
            height: -1,
            fixed: -1,
            complex: -1,
            remark: -1,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: -1,
          });
        }
      }
    }
  }

  return tableData;
};
const getDiys = (data, department = 0) => {
  if (!data || data.length === 0) { return []; }
  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    let payBill = data[i].bills.filter(r => r.total_oms !== 0);
    let count2 = department === 124 && data[i].price !== data[i].priceOriginal ? data[i].price + '（原价:' + data[i].priceOriginal + ')' : data[i].price;
    let complexs = '';
    if (data[i].complex) {
      data[i].complex.map(r => {
        complexs = complexs + r + '|';
        return complexs;
      });
      complexs = complexs.slice(0, complexs.length - 1);
    }
    if (data[i].materials.length > payBill.length) {
      for (let j = 0; j < data[i].materials.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: data[i].materials.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            width: data[i].width,
            height: data[i].height,
            method: data[i].fixType,
            parts: `${data[i].part ? data[i].part : ''}${complexs}`,
            remark: data[i].remark,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: data[i].materials.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            width: -1,
            height: -1,
            method: -1,
            parts: -1,
            remark: -1,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: -1,
          });
        }
      }
    } else {
      for (let j = 0; j < payBill.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: payBill.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            width: data[i].width,
            height: data[i].height,
            method: data[i].fixType,
            parts: `${data[i].part ? data[i].part : ''}${complexs}`,
            remark: data[i].remark,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: payBill.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            width: -1,
            height: -1,
            method: -1,
            parts: -1,
            remark: -1,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: -1,
          });
        }
      }
    }
  }
  return tableData;
};
const getRodrails = (data, department = 0) => {
  if (!data || data.length === 0) { return []; }
  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    let payBill = data[i].bills.filter(r => r.total_oms !== 0);
    let count2 = department === 124 && data[i].price !== data[i].priceOriginal ? data[i].price + '（原价:' + data[i].priceOriginal + ')' : data[i].price;
    let complexs = '';
    if (data[i].complex) {
      data[i].complex.map(r => {
        complexs = complexs + r + '|';
        return complexs;
      });
      complexs = complexs.slice(0, complexs.length - 1);
    }
    for (let j = 0; j < payBill.length; j++) {
      if (j === 0) {
        tableData.push({
          key: i + 1,
          single: i % 2,
          col: payBill.length,
          index: i + 1,
          room: data[i].room,
          pic: data[i].pic,
          name: data[i].name,
          sort: data[i].sort,
          floor: data[i].layers,
          length: data[i].length,
          method: data[i].fixType,
          shard: data[i].piece,
          head: data[i].header,
          remark: data[i].remark,
          parts: `${data[i].part ? data[i].part : ''}${complexs}`,
          billName: payBill[j].name,
          pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
          number: payBill[j].quantity,
          subtotal: payBill[j].total_oms,
          count: count2,
        });
      } else {
        tableData.push({
          key: `${i}${j}`,
          single: i % 2,
          col: payBill.length,
          index: -1,
          room: -1,
          pic: -1,
          name: -1,
          sort: data[i].sort,
          floor: -1,
          length: -1,
          method: -1,
          shard: -1,
          head: -1,
          remark: -1,
          parts: -1,
          billName: payBill[j].name,
          pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
          number: payBill[j].quantity,
          subtotal: payBill[j].total_oms,
          count: -1,
        });
      }
    }

  }
  return tableData;
};
const getStandards = (data, department = 0) => {
  if (!data || data.length === 0) { return []; }
  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    let payBill = data[i].bills.filter(r => r.total_oms !== 0);
    let count2 = department === 124 && data[i].price !== data[i].priceOriginal ? data[i].price + '（原价:' + data[i].priceOriginal + ')' : data[i].price;
    if (data[i].materials.length > payBill.length) {
      for (let j = 0; j < data[i].materials.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: data[i].materials.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            buy: data[i].purchase,
            remark: data[i].remark,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: data[i].materials.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            buy: -1,
            remark: -1,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: -1,
          });
        }
      }
    } else {
      for (let j = 0; j < payBill.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: payBill.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            buy: data[i].purchase,
            remark: data[i].remark,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: count2,
          });
        } else {
          tableData.push({
            key: `${i}${j}`,
            single: i % 2,
            col: payBill.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            buy: -1,
            remark: -1,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: -1,
          });
        }
      }
    }
  }
  return tableData;
};
const getPads = (data, department = 0) => {
  if (!data || data.length === 0) { return []; }
  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    let payBill = data[i].bills.filter(r => r.total_oms !== 0);
    let count2 = department === 124 && data[i].price !== data[i].priceOriginal ? data[i].price + '（原价:' + data[i].priceOriginal + ')' : data[i].price;
    if (data[i].materials.length > payBill.length) {
      for (let j = 0; j < data[i].materials.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: data[i].materials.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            length: data[i].length,
            depth: data[i].depth,
            thickness: data[i].thickness,
            complex: data[i].complex,
            work: data[i].work,
            remark: data[i].remark,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: count2,
          });
        } else {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: data[i].materials.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            length: -1,
            depth: -1,
            thickness: -1,
            complex: -1,
            work: -1,
            remark: -1,
            location: data[i].materials[j].position,
            source: data[i].materials[j].source,
            partName: data[i].materials[j].name,
            doors: data[i].materials[j].breadth,
            direction: data[i].materials[j].direction,
            space: '',
            billName: payBill[j] ? payBill[j].name : '',
            pay: payBill[j] ? parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)) : '',
            number: payBill[j] ? payBill[j].quantity : '',
            subtotal: payBill[j] ? payBill[j].total_oms : '',
            count: -1,
          });
        }
      }
    } else {
      for (let j = 0; j < payBill.length; j++) {
        if (j === 0) {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: payBill.length,
            index: i + 1,
            room: data[i].room,
            pic: data[i].pic,
            name: data[i].name,
            sort: data[i].sort,
            length: data[i].length,
            depth: data[i].depth,
            thickness: data[i].thickness,
            complex: data[i].complex,
            work: data[i].work,
            remark: data[i].remark,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: count2,
          });
        } else {
          tableData.push({
            key: i + 1,
            single: i % 2,
            col: payBill.length,
            index: -1,
            room: -1,
            pic: -1,
            name: -1,
            sort: data[i].sort,
            length: -1,
            depth: -1,
            thickness: -1,
            complex: -1,
            work: -1,
            remark: -1,
            location: data[i].materials[j] ? data[i].materials[j].position : '',
            source: data[i].materials[j] ? data[i].materials[j].source : '',
            partName: data[i].materials[j] ? data[i].materials[j].name : '',
            doors: data[i].materials[j] ? data[i].materials[j].breadth : '',
            direction: data[i].materials[j] ? data[i].materials[j].direction : '',
            space: '',
            billName: payBill[j].name,
            pay: parseFloat((payBill[j].unit_oms + payBill[j].add_oms).toFixed(2)),
            number: payBill[j].quantity,
            subtotal: payBill[j].total_oms,
            count: -1,
          });
        }
      }
    }
  }
  return tableData;
};
const getCount = (data) => {
  let postage = 0;
  let special = [124, 103];
  if (!data.posts || data.posts.length === 0) {
    return;
  } else {
    data.posts.map(item => {
      postage = postage + item.price + item.add;
      return item;
    });
    return (
      <div className={`col-t vertical-lt w100 ${styles['count']}`}>
        <div className='row-l w100'>
          <div className={styles['count1']}>其他费用</div>
          <div className={styles['count2']}>
            邮费：{postage}元{special.indexOf(data.department) > -1 && data.posts[0].name ?
              '（' + data.posts[0].name + data.posts[0].pay + '） ' : ''}
            整单调价： {data.price_add}元
          </div>
          <div className={`row ${styles['count3']}`}>
            <div>
              总计：{data.price}元
            </div>
            <div className={styles['count4']}>
              {data.department === 124 ? '（原价:' + data.priceOriginal + ')' : ''}
            </div>
          </div>
        </div>
        {
          data.department === 118 &&
          <div className={`${styles['attention1']} w100 row`}>
            <div>温馨提示：</div>
            <div>2.8门幅定宽做，单色宽度超过2.7会有竖拼缝，部分型号定高定宽都可以做，但是纹路会发生变化。请您根据需求下单！</div>
          </div>
        }
      </div>
    );

  }
};

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payMent: 0,
      data: {
        order: {
          addOms: 0,
          department: 0
        },
        products: [],
        room: [],
        post: [],
        balance: 0,
      },
      loadings: true,
      isTable: 1,
      select: ['f', 'f', 'f', 'f', 'f'],
      baseUrls: '',
      records: '浙ICP备17044703号-5',
    };
    this.postInfoModal = React.createRef();
  }
  componentDidMount() {
    let url = window.location;
    if (url.search) {
      let obj = searchConvertObj(url.search);
      let id = obj.o;
      this.onSearch(id);
    }
  }
  render() {
    const { data, isTable, baseUrls, records } = this.state;
    let isShowPost, stateImgSrc, tableTitle, tableCount, department;
    let tableCurtains = [];
    let tableValances = [];
    let tableDiys = [];
    let tableRodrails = [];
    let tableStandards = [];
    let tablePads = [];
    let tableLength = 0;
    if (!isTable) {
      isShowPost = getConfig(data.order.department).isShowPost;
      stateImgSrc = getOrderState(data.order.orderState);
      department = data.order.department;
    } else {
      department = data.department;
      tableTitle = getTitle(data, department);
      tableCount = getCount(data);
      tableCurtains = getCurtains(data.products.curtains, department);
      tableValances = getValances(data.products.valances, department);
      tableDiys = getDiys(data.products.diys, department);
      tablePads = getPads(data.products.pads, department);
      tableRodrails = getRodrails(data.products.rodrails, department);
      tableStandards = getStandards(data.products.standards, department);
      tableLength = tableCurtains.length + tableValances.length + tableDiys.length + tableRodrails.length + tableStandards.length;
    }
    //窗帘
    const columnsCurtains = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '房间',
        dataIndex: 'room',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        except: [],
        only: [94, 114, 1182],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: <img alt='' src={text ? baseUrls + text : baseUrls + 'defaultSku.jpg'} onClick={() => this.larger(text)} style={{ width: '80px', height: '80px' }} />,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        except: [],
        only: [],
        width: 180,
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '宽',
        dataIndex: 'width',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '高',
        dataIndex: 'height',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工方式',
        dataIndex: 'method',
        align: 'center',
        except: [118],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: department === 94 ? '分片|净宽' : '分片|倍率',
        dataIndex: 'shard',
        align: 'center',
        except: [118],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工方式+分片|倍率',
        dataIndex: 'methodShard',
        align: 'center',
        except: [],
        only: [118],
        width: 180,
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: department === 124 ? '加工备注' : '复杂加工',
        dataIndex: 'complex',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },

      {
        title: '用料明细',
        dataIndex: 'materials',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '位置',
            dataIndex: 'location',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '来源',
            dataIndex: 'source',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '品名',
            dataIndex: 'partName',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '门幅',
            dataIndex: 'doors',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '方向',
            dataIndex: 'direction',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
        ]
      },
      {
        title: '销售清单',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '名称',
            dataIndex: 'billName',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '单价',
            dataIndex: 'pay',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '数量',
            dataIndex: 'number',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '小计',
            dataIndex: 'subtotal',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '合计',
            dataIndex: 'count',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              if (text === -1) {
                return {
                  props: {
                    rowSpan: 0,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              } else {
                return {
                  children: text,
                  props: {
                    rowSpan: record.col,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              }
            }
          },
        ]
      }

    ].filter(item => {
      if (!item.except.includes(department) && item.only.length === 0) {
        let newItem = item;
        if (item.title === '用料明细' || item.title === '销售清单') {
          newItem.children = item.children.filter(i => !i.except.includes(department));
        }
        return newItem;
      } else if (item.only.includes(department)) {
        return item;
      } else {
        return '';
      }
    });
    //窗幔
    const columnsValances = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '房间',
        dataIndex: 'room',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        except: [],
        only: [94, 114, 1182],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: <img alt='' src={text ? baseUrls + text : baseUrls + 'defaultSku.jpg'} onClick={() => this.larger(text)} style={{ width: '80px', height: '80px' }} />,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },

      {
        title: '宽',
        dataIndex: 'width',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '高',
        dataIndex: 'height',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },

      {
        title: '固定方式',
        dataIndex: 'fixed',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '复杂加工',
        dataIndex: 'complex',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工备注',
        dataIndex: 'remark',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '用料明细',
        dataIndex: 'materials',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '位置',
            dataIndex: 'location',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '来源',
            dataIndex: 'source',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '品名',
            dataIndex: 'partName',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '门幅',
            dataIndex: 'doors',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '方向',
            dataIndex: 'direction',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
        ]
      },
      // {
      //   title: ' ',
      //   dataIndex: 'method',
      //   align: 'center',
      //   except: [],
      //   only: [124],
      //   className: styles['space'],
      //   render: (text, record, index) => {
      //     if (text === -1) {
      //       return {
      //         props: {
      //           rowSpan: 0,
      //           className: record.single === 1 ? styles['tableRow'] : '',
      //         }
      //       };
      //     } else {
      //       return {
      //         children: '    ',
      //         props: {
      //           rowSpan: record.col,
      //           className: record.single === 1 ? styles['tableRow'] : '',
      //         }
      //       };
      //     }
      //   }
      // },
      {
        title: '销售清单',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '名称',
            dataIndex: 'billName',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '单价',
            dataIndex: 'pay',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '数量',
            dataIndex: 'number',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '小计',
            dataIndex: 'subtotal',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '合计',
            dataIndex: 'count',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              if (text === -1) {
                return {
                  props: {
                    rowSpan: 0,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              } else {
                return {
                  children: text,
                  props: {
                    rowSpan: record.col,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              }
            }
          },
        ]
      },

    ].filter(item => {
      if (!item.except.includes(department) && item.only.length === 0) {
        let newItem = item;
        if (item.title === '用料明细' || item.title === '销售清单') {
          newItem.children = item.children.filter(i => !i.except.includes(department));
        }
        return newItem;
      } else if (item.only.includes(department)) {
        return item;
      } else {
        return '';
      }
    });
    //其他
    const columnsDiys = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '房间',
        dataIndex: 'room',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        except: [],
        only: [94, 114, 1182],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: <img alt='' src={text ? baseUrls + text : baseUrls + 'defaultSku.jpg'} onClick={() => this.larger(text)} style={{ width: '80px', height: '80px' }} />,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '宽',
        dataIndex: 'width',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '高',
        dataIndex: 'height',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工方式',
        dataIndex: 'method',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '复杂加工',
        dataIndex: 'parts',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工备注',
        dataIndex: 'remark',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '用料明细',
        dataIndex: 'materials',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '位置',
            dataIndex: 'location',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '来源',
            dataIndex: 'source',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '品名',
            dataIndex: 'partName',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '门幅',
            dataIndex: 'doors',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '方向',
            dataIndex: 'direction',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
        ]
      },
      // {
      //   title: ' ',
      //   dataIndex: 'method',
      //   align: 'center',
      //   except: [],
      //   only: [124],
      //   className: styles['space'],
      //   render: (text, record, index) => {
      //     if (text === -1) {
      //       return {
      //         props: {
      //           rowSpan: 0,
      //           className: record.single === 1 ? styles['tableRow'] : '',
      //         }
      //       };
      //     } else {
      //       return {
      //         children: '    ',
      //         props: {
      //           rowSpan: record.col,
      //           className: record.single === 1 ? styles['tableRow'] : '',
      //         }
      //       };
      //     }
      //   }
      // },
      {
        title: '销售清单',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '名称',
            dataIndex: 'billName',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '单价',
            dataIndex: 'pay',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '数量',
            dataIndex: 'number',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '小计',
            dataIndex: 'subtotal',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '合计',
            dataIndex: 'count',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              if (text === -1) {
                return {
                  props: {
                    rowSpan: 0,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              } else {
                return {
                  children: text,
                  props: {
                    rowSpan: record.col,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              }
            }
          },
        ]
      },

    ].filter(item => {
      if (!item.except.includes(department) && item.only.length === 0) {
        let newItem = item;
        if (item.title === '用料明细' || item.title === '销售清单') {
          newItem.children = item.children.filter(i => !i.except.includes(department));
        }
        return newItem;
      } else if (item.only.includes(department)) {
        return item;
      } else {
        return '';
      }
    });
    //杆轨
    const columnsRodrails = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '房间',
        dataIndex: 'room',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        except: [],
        only: [94, 114, 1182],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: <img alt='' src={text ? baseUrls + text : baseUrls + 'defaultSku.jpg'} onClick={() => this.larger(text)} style={{ width: '80px', height: '80px' }} />,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },

      {
        title: '层数',
        dataIndex: 'floor',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '长度',
        dataIndex: 'length',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '安装方式',
        dataIndex: 'method',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '分段',
        dataIndex: 'shard',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '装饰头',
        dataIndex: 'head',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '复杂加工',
        dataIndex: 'parts',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工备注',
        dataIndex: 'remark',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '销售清单',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '名称',
            dataIndex: 'billName',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '单价',
            dataIndex: 'pay',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '数量',
            dataIndex: 'number',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '小计',
            dataIndex: 'subtotal',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '合计',
            dataIndex: 'count',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              if (text === -1) {
                return {
                  props: {
                    rowSpan: 0,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              } else {
                return {
                  children: text,
                  props: {
                    rowSpan: record.col,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              }
            }
          },
        ]
      },

    ].filter(item => {
      if (!item.except.includes(department) && item.only.length === 0) {
        let newItem = item;
        if (item.title === '销售清单') {
          newItem.children = item.children.filter(i => !i.except.includes(department));
        }
        return newItem;
      } else if (item.only.includes(department)) {
        return item;
      } else {
        return '';
      }
    });
    //成品原料
    const columnsStandards = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '房间',
        dataIndex: 'room',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        except: [],
        only: [94, 114, 1182],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: <img alt='' src={text ? baseUrls + text : baseUrls + 'defaultSku.jpg'} onClick={() => this.larger(text)} style={{ width: '80px', height: '80px' }} />,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '采购方式(零剪/整卷)',
        dataIndex: 'buy',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                colSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工备注',
        dataIndex: 'remark',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                colSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '用料明细',
        dataIndex: 'materials',
        align: 'center',
        except: [],
        only: [94],
        className: styles['space'],
        children: [
          {
            title: '位置',
            dataIndex: 'location',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '来源',
            dataIndex: 'source',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '品名',
            dataIndex: 'partName',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '门幅',
            dataIndex: 'doors',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '方向',
            dataIndex: 'direction',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
        ]
      },
      {
        title: '销售清单',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '名称',
            dataIndex: 'billName',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '单价',
            dataIndex: 'pay',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '数量',
            dataIndex: 'number',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '小计',
            dataIndex: 'subtotal',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '合计',
            dataIndex: 'count',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              if (text === -1) {
                return {
                  props: {
                    rowSpan: 0,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              } else {
                return {
                  children: text,
                  props: {
                    rowSpan: record.col,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              }
            }
          },
        ]
      },
    ].filter(item => {
      if (!item.except.includes(department) && item.only.length === 0) {
        let newItem = item;
        if (item.title === '销售清单') {
          newItem.children = item.children.filter(i => !i.except.includes(department));
        }
        return newItem;
      } else if (item.only.includes(department)) {
        return item;
      } else {
        return '';
      }
    });
    //软垫
    const columnsPads = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '房间',
        dataIndex: 'room',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        except: [],
        only: [94, 114, 1182],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: <img alt='' src={text ? baseUrls + text : baseUrls + 'defaultSku.jpg'} onClick={() => this.larger(text)} style={{ width: '80px', height: '80px' }} />,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '长',
        dataIndex: 'length',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '深',
        dataIndex: 'depth',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '厚',
        dataIndex: 'thickness',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '复杂加工',
        dataIndex: 'complex',
        align: 'center',
        except: [118],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工方式',
        dataIndex: 'work',
        align: 'center',
        except: [118],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '加工备注',
        dataIndex: 'remark',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        render: (text, record, index) => {
          if (text === -1) {
            return {
              props: {
                rowSpan: 0,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          } else {
            return {
              children: text,
              props: {
                rowSpan: record.col,
                className: record.single === 1 ? styles['tableRow'] : '',
              }
            };
          }
        }
      },
      {
        title: '用料明细',
        dataIndex: 'materials',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '位置',
            dataIndex: 'location',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '来源',
            dataIndex: 'source',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '品名',
            dataIndex: 'partName',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '门幅',
            dataIndex: 'doors',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '方向',
            dataIndex: 'direction',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
        ]
      },
      {
        title: '销售清单',
        align: 'center',
        except: [],
        only: [],
        className: styles['space'],
        children: [
          {
            title: '名称',
            dataIndex: 'billName',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '单价',
            dataIndex: 'pay',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '数量',
            dataIndex: 'number',
            align: 'center',
            except: [118],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '小计',
            dataIndex: 'subtotal',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              return {
                children: text,
                props: {
                  className: record.single === 1 ? styles['tableRow'] : '',
                }
              };
            }
          },
          {
            title: '合计',
            dataIndex: 'count',
            align: 'center',
            except: [],
            only: [],
            className: styles['space'],
            render: (text, record, index) => {
              if (text === -1) {
                return {
                  props: {
                    rowSpan: 0,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              } else {
                return {
                  children: text,
                  props: {
                    rowSpan: record.col,
                    className: record.single === 1 ? styles['tableRow'] : '',
                  }
                };
              }
            }
          },
        ]
      }
    ].filter(item => {
      if (!item.except.includes(department) && item.only.length === 0) {
        let newItem = item;
        if (item.title === '销售清单') {
          newItem.children = item.children.filter(i => !i.except.includes(department));
        }
        return newItem;
      } else if (item.only.includes(department)) {
        return item;
      } else {
        return '';
      }
    });
    // console.log(window.location.href);
    // let url = window.location.href;
    if (isTable === 1) {
      return <div className='w100 position hc100'>
        <div className='content-padding fz20 tc'>
          您所查询的订单不存在
        </div>
        <div className={`${styles['foot']} w100 tc`}>
          <a className="row cursor-pointer  color0 fz18" href="http://beian.miit.gov.cn/">
            {records}
          </a>
        </div>
      </div>;

    } else if (isTable === true) {
      return (
        <div className={`${styles['kindName']} position`}>
          {tableTitle}
          {tableCurtains && tableCurtains.length > 0 ? <Table
            bordered
            pagination={false}
            size="small"
            columns={columnsCurtains}
            dataSource={tableCurtains}
            title={() => {
              return <div className={styles['tableTitle']}>窗帘</div>;
            }}
            rowSelection={false}
            onRow={this.onClickRow.bind(this, 0)}
            rowClassName={this.setRowClassName.bind(this, 0)}
          /> : ''}
          {tableValances && tableValances.length > 0 ? <Table
            bordered
            pagination={false}
            size="small"
            columns={columnsValances}
            dataSource={tableValances}
            title={() => {
              return <div className={styles['tableTitle']}>窗幔</div>;
            }}
            rowSelection={false}
            onRow={this.onClickRow.bind(this, 1)}
            rowClassName={this.setRowClassName.bind(this, 1)}
          /> : ''}
          {tableRodrails && tableRodrails.length > 0 ? <Table
            bordered
            pagination={false}
            size="small"
            columns={columnsRodrails}
            dataSource={tableRodrails}
            title={() => {
              return <div className={styles['tableTitle']}>杆轨</div>;
            }}
            rowSelection={false}
            onRow={this.onClickRow.bind(this, 2)}
            rowClassName={this.setRowClassName.bind(this, 2)}
          /> : ''}
          {tableDiys && tableDiys.length > 0 ? <Table
            bordered
            pagination={false}
            size="small"
            columns={columnsDiys}
            dataSource={tableDiys}
            title={() => {
              return <div className={styles['tableTitle']}>其他</div>;
            }}
            rowSelection={false}
            onRow={this.onClickRow.bind(this, 3)}
            rowClassName={this.setRowClassName.bind(this, 3)}
          /> : ''}
          {tablePads && tablePads.length > 0 &&
            <Table
              bordered
              pagination={false}
              size="small"
              columns={columnsPads}
              dataSource={tablePads}
              title={() => {
                return <div className={styles['tableTitle']}>软垫</div>;
              }}
              rowSelection={false}
              onRow={this.onClickRow.bind(this, 4)}
            />}
          {tableStandards && tableStandards.length > 0 ? <Table
            bordered
            pagination={false}
            size="small"
            columns={columnsStandards}
            dataSource={tableStandards}
            title={() => {
              return <div className={styles['tableTitle']}>成品原料</div>;
            }}
            rowSelection={false}
            onRow={this.onClickRow.bind(this, 4)}
            rowClassName={this.setRowClassName.bind(this, 4)}
          /> : ''}
          {tableCount}
          <div className={styles['priviewBox']} id='preBox'>
            <img alt='' className={styles['priviewPic']} id='previewPic' />
            <img alt='' onClick={this.close} className={styles['close']} />
          </div>
          <div className={`${tableLength > 5 ? 'foot1' : 'foot2'} w100 tc`}>
            <a className="row cursor-pointer  color0 fz18" href="http://beian.miit.gov.cn/">
              {records}
            </a>
          </div>
          {/* <div className='foot1'>  </div> */}
        </div >
      );
    } else if (isTable === false) {
      return (
        <div className={`${styles['page']} color3`}>
          <img
            alt='暂无图片'
            className={styles['background']}
            src={backgrund}
          />
          <div>
            <div className={'row-sb'} style={{ marginTop: '18px', marginBottom: '28px' }}>
              <div className={'row-l'} >
                <img
                  alt='暂无图片'
                  src={stateImgSrc}
                  className={styles['state-img']}
                />
                <span className={'fz20 color1'}>{data.order.orderState}</span>
              </div>
              <div>
                <button id='create-button' className={`${styles['create-button']} fz18`} onClick={this.createImg} >
                  生成图片
                </button>
              </div>
            </div>
            <div className={`row-l vertical-lt ${styles['content']}`}>
              <img
                alt='暂无图片'
                className={styles['icon']}
                src={landmark}
              />
              <div>
                <div>
                  {data.order.name} {data.order.phone}
                </div>
                <div className={styles['margin-top-6']}>
                  {data.order.province}{data.order.city}{data.order.district}{data.order.address}
                </div>
              </div>

            </div>
          </div>

          <div className={`col ${styles['content']}`}>
            {data.products.map((item, index) => {
              return (
                <div key={index} className={'w100'} style={{ marginBottom: '12px' }}>
                  <div className={'row-sb vertical-lt w100'}>
                    <div className={'row-l vertical-lt'}>
                      <img alt='' src={item.pic !== '' ?
                        // `${baseUrl}${item.pic}`
                        `${item.pic}`
                        : forty}
                        className={styles['product-photo']} />
                      {/* 产品信息 */}
                      <div >
                        <div>{getRoom(item, data.room, department)}</div>
                        {
                          getProduct(item, data.order.department)
                        }
                      </div>
                    </div>
                    <div className={'color2'} style={{ minWidth: '48px' }}>{item.process}</div>
                  </div>
                  <div className={'col-t vertical-lt w100'}>
                    {department === 80 && item.type === '成品' ?
                      <div className={styles['detail']}>
                        <div className={'bold fz16'}>产品:</div>
                        {item.material.map((maItem, maIndex) => (
                          <div key={maIndex} className={'c333'}>{maItem.name + ' 1' + maItem.unit}</div>
                        ))}
                      </div> : ''}
                    {
                      department !== 1212 &&
                      <div className={styles['detail']}>
                        <div className={'bold fz16'}>明细:</div>
                        {item.bill.map((billItem, billIndex) => (
                          <div key={billIndex} className={'c333'}>{billItem}</div>
                        ))}
                      </div>
                    }
                    <div className={'w100 row-r'}>
                      总计:￥<span className={'color2'}>{(item.totalOms + item.addOms).toFixed(1)}</span>
                    </div>
                  </div>
                  {
                    department === 1205 && item.type === '窗帘' && isCurtain(item.component) &&
                    <div className='color7'>友情提示：本公司所有布帘均为定宽产品，单幅单色超过帘宽2.7米（墙宽1.34米）以上都有拼接</div>
                  }
                </div>
              );
            })}
          </div>

          <div className={`col ${styles['content']}`}>
            {data.post.map((item, index) => {
              return (
                <div className={'w100'} key={index}>
                  <div className={'w100 row-sb'}>
                    <span><span className={'fz16 color2'}>{item.company}</span></span>
                    {
                      isShowPost ?
                        <span>
                          ￥:
                          <span className={'fz18 color2'}>
                            {item.addOms === 0 ? '' : <span style={{ textDecoration: 'line-through', color: '#999', marginRight: '4px' }}>{item.priceOms}</span>}
                            {item.priceOms + item.addOms}
                          </span>
                        </span> :
                        <span></span>
                    }
                  </div>
                  <div style={{ paddingLeft: '10px' }}>地址:{item.receiver},{item.phone},{item.province}{item.city}{item.district}{item.address}</div>
                  {
                    item.numbers && item.numbers.length > 0 &&
                    <div className={'w100 row-sb vertical-lt'} style={{ paddingLeft: '10px' }}>
                      <div>快递编号</div>
                      <div className={'col-t'}>
                        {
                          item.numbers.map((numItem, numIndex) => {
                            return (
                              <div key={numIndex} className={'row'}>
                                <CopyToClipboard text={numItem}>
                                  <div onClick={this.success} >{numItem}<img src={copyImg} alt={'复制'} style={{ marginLeft: 3, marginTop: -1 }} /></div>
                                </CopyToClipboard>
                                <div onClick={this.checkPost.bind(this, numItem, item.companyId)} className={'color2'}>查看物流</div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  }
                </div>
              );
            })}
            <div className={'w100 row-sb vertical-lt'}>
              <div className={'color2'}></div>
              <div className={'col vertical-rb'}>
                {
                  data.order.addOms !== 0 && data.order.department !== 73 && data.order.department !== 69 &&
                  <div>金额:￥<span className={`c999 ${styles['underline']}`}>{data.order.priceOms}</span></div>
                }
                <div>
                  实际金额:￥ <span className={'fz16 color2'}>{(data.order.priceOms + data.order.addOms).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`row-sb ${styles['content']}`}>
            <div className={'col vertical-lt'}>
              <div>订单编号:{data.order.id}</div>
              {data.order.createTime && (<div>创建时间:{moment(data.order.createTime).format(dateFormat)}</div>)}
              {data.order.pushTime && (<div>推送时间:{moment(data.order.pushTime).format(dateFormat)}</div>)}
            </div>
            <div className={'fz20 color2'}></div>
            {/* <div className={'fz20 color2'}>当前余额：{data.balance}</div> */}
          </div>
          <div className={'row'}>
            {/* {
              department === 123 ?
                <div id='payments' className={`row ${styles['code']}`}>
                </div>
                : */}
            <img alt='暂无图片' src={data.order.departmentPay} onError={this.onError} className={styles['img']} />

            {/* } */}
          </div>
          <div className={'col'}>
            <a className='cursor-pointer color3' href='https://beian.miit.gov.cn/#/Integrated/recordQuery'>
              浙ICP备17044703号-6
            </a>
            <a className='row-l cursor-pointer color3' href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302000713'>
              <img src={recordIcon} alt='暂无图片' />
              <p style={{ margin: 0 }}>浙公网安备 33060302000713号</p>
            </a>
          </div>
          <PostInfoModal ref={this.postInfoModal} />
        </div>
      );
    }
  }

  async onSearch(id) {
    let rsp = await getOrder(id);
    if (rsp.status !== 200) { return; }
    let data = rsp.data.result;
    let baseUrls = baseUrl ? baseUrl : rsp.data.resource + '/resource/';
    if (!rsp.data.isNewFormat) {
      if (data.order.department === 123 || data.order.department === 122 || data.order.department === 128
        || data.order.department === 1205
      ) {
        data.order.departmentPay = 'data:image/jpg;base64,' + rsp.data.payment;
      } else if (data.order.department === 80) {
        let departmentPay = 'https://zhiyan-erp.oss-cn-hangzhou.aliyuncs.com/resource/80.jpg';
        data.order.departmentPay = departmentPay;
        (async () => {
          let base64Pay = await this.getBase64(departmentPay);
          data.order.departmentPay = base64Pay;
          this.setState({
            data: data
          });
        })();
      } else {
        let departmentPay = `https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/payment/${data.order.department}.jpg`;
        data.order.departmentPay = departmentPay;
        (async () => {
          let base64Pay = await this.getBase64(departmentPay);
          data.order.departmentPay = base64Pay;
          this.setState({
            data: data
          });
        })();
      }
      data.products.forEach((item, index) => {
        let { pic } = getName(item);
        item.pic = pic ? baseUrls + pic : '';
      });
    }
    this.setState({
      data,
      baseUrls,
      isTable: rsp.data.isNewFormat
    });
    // if (rsp.data.payMents) {
    //   this.code(rsp.data.buffer);
    // }
  }
  //生成二维码
  async code(e) {
    var qrcode = require('qrcode');
    let paymentBox = document.getElementById('payments');
    for (let i = 0; i < e.length; i++) {
      // if (e[i].type === '微信支付') {
      let box = document.createElement('div');
      paymentBox.appendChild(box);
      var collectImg = new Image();
      box.appendChild(collectImg);
      let collect = document.createElement('canvas');
      box.appendChild(collect);
      let imgId = e[i].type === '支付宝' ? 'imgz' : 'imgw';
      collectImg.setAttribute('id', `${imgId}`);
      let type = e[i].type === '支付宝' ? 'collectz' : 'collectw';
      collect.setAttribute('id', `${type}`);

      // box.style.background = e[i].type === '支付宝' ? "url('https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/payment/xbzfb.png')" : "url('/static/media/xbwx.png')";
      box.style.background = e[i].type === '支付宝' ? "url('https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/payment/xbzfb.png')" : "url('https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/payment/xbwx.png')";
      box.style.backgroundSize = 'cover';
      box.style.visibility = 'inherit';
      qrcode.toCanvas(collect, e[i].code, function (error) {
        if (error) {
          console.log(error);
        }
      });
      let imgSrc = await collect.toDataURL('image/png', 1.0);
      document.getElementById(imgId).src = imgSrc;
      box.removeChild(collect);
      // }
    }
  }

  larger(url) {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      //移动端
    } else {
      let imgUrl = url === '' ? 'defaultSku.jpg' : url;
      let img = document.getElementById('previewPic');
      let box = document.getElementById('preBox');
      img.style.background = `url(${this.data, baseUrls + imgUrl}) `;
      img.style.backgroundSize = 'cover';
      box.style.visibility = 'inherit';
    }
  }
  close() {
    let box = document.getElementById('preBox');
    box.style.visibility = 'hidden';
  }

  success() {
    message.success({
      content: '快递编号复制成功'
    });
  }

  checkPost = async (numbers, companyId) => {
    this.postInfoModal.current.onShowModal(numbers, companyId);
  }
  //判断是否超出当前屏幕高度
  hasScrollbar() {
    return document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight);
  }
  createImg = async () => {
    let dom = document.getElementById('root');
    const scale = 3;
    let canvas = await html2canvas(dom, {
      // allowTaint: false,
      useCORS: true,
      width: dom.clientWidth,
      height: dom.clientHeight,
      scale: scale,
      y: 0,
    });
    canvas.setAttribute('id', 'canvas');
    // 画布转base64
    let dataUrl = await canvas.toDataURL('image/png', 1.0);
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // 移动端
      canvas.setAttribute('crossOrigin', 'anonymous');
      let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      save_link.href = dataUrl;
      save_link.download = '订单信息';
      let event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      save_link.dispatchEvent(event);
    } else {
      // PC端
      if (navigator.clipboard !== undefined && typeof ClipboardItem === 'function') {
        let data = await fetch(dataUrl);
        let blob = await data.blob();
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })
        ]);
        message.info('图片已复制到剪贴板');
      } else {
        canvas.setAttribute('crossOrigin', 'anonymous');
        let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
        save_link.href = dataUrl;
        save_link.download = '订单信息';
        let event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        save_link.dispatchEvent(event);
      }
    }
  }

  getBase64(url) {
    return new Promise((resolve, reject) => {
      var Img = new Image();
      var dataURL = '';
      Img.setAttribute('crossOrigin', 'Anonymous');
      Img.src = url + '?v=' + Math.random();
      Img.onload = function () {
        // 要先确保图片完整获取到，这是个异步事件
        var canvas = document.createElement('canvas'); // 创建canvas元素
        var width = Img.width; // 确保canvas的尺寸和图片一样
        var height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height); // 将图片绘制到canvas中
        dataURL = canvas.toDataURL('image/jpeg'); // 转换图片为dataURL
        resolve(dataURL);
      };
    });
  }

  onError(e) {
    e.target.src = borders;
  }
  setRowClassName = (index, record) => {
    // let select = [...this.state.select];
    // return record.sort === select[index] ? 'back-color0' : record.single === 1 ? 'back-color1' : '';
  }
  onClickRow = (index, record) => {
    return {
      onClick: () => {
        let select = [...this.state.select];
        select[index] = record.sort;
        this.setState({
          select
        });
      }
    };
  }
}

export default Order;
//打印
// let dom = document.getElementById('content');
// const scale = 3;
// let canvas = await html2canvas(dom, {
//   // allowTaint: false,
//   useCORS: true,
//   width: dom.clientWidth,
//   height: dom.clientHeight,
//   // scale: scale,
//   y: 0,
// });
// let iframe = document.createElement('IFRAME');
// iframe.setAttribute('style', 'position:absolute;height:0px;left:200px;top:100px;');
// document.body.appendChild(iframe);
// iframe.contentDocument.body.appendChild(canvas);
// // iframe.contentWindow.focus();
// iframe.contentWindow.print();


// -----------------------
    // var el = document.getElementById('content');
    // var iframe = document.createElement('IFRAME');
    // var doc = null;
    // iframe.setAttribute('style', 'position:absolute;0px;height:0px;left:-150px;top:-200px;');
    // document.body.appendChild(iframe);
    // doc = iframe.contentWindow.document;
    // doc.write('<div>' + el.innerHTML + '</div>');
    // doc.close();
    // iframe.contentWindow.focus();
    // iframe.contentWindow.print();

    // ---------------------------------------
    // let dom = document.getElementById('content');
    // const scale = 3;
    // let canvas = await html2canvas(dom, {
    //   // allowTaint: false,
    //   useCORS: true,
    //   width: dom.clientWidth,
    //   height: dom.clientHeight,
    //   scale: scale,
    //   y: 0,
    // });
    // let iframe = document.createElement('IFRAME');
    // iframe.setAttribute('style', 'position:absolute;0px;height:0px;left:-150px;top:-200px;');
    // document.body.appendChild(iframe);
    // let doc = iframe.contentWindow.document;
    // doc.write('<div>' + canvas + '</div>');
    // iframe.contentDocument.body.appendChild(canvas);
    // doc.close();
    // iframe.contentWindow.focus();
    // iframe.contentWindow.print();