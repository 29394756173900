
import React, { Component } from 'react';
import styles from './app.module.css';
import { Button, Image, Input, message, Spin } from 'antd';
import { searchConvertObj } from '../../utils/utilHelper';
import { getResult, getRecords } from '../../services/order';
import wx from 'weixin-js-sdk';

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecord: false,
      isNormal: false,
      errorMsg: '',
      brand: 0,
      code: '',
      state: '',
      appid: '',
      attach: '',
      records: [],
      loading: false,
      isWeixin: 'True',
    };
  }
  componentDidMount() {
    let obj = searchConvertObj(window.location.search);
    let res = obj.state.split('-');
    this.setState({
      code: obj.code,
      state: obj.state,
      appid: obj.appid,
      isWeixin: res[0],
      brand: res[1],
      loading: true,
    }, () => {
      // if (!obj.code && !obj.appid) {
      //   let isNormal = res[2] === 'exit' ? true : false;
      //   let errorMsg = res[2] === 'exit' ? '' : '不存在';
      //   this.setState({
      //     loading: false,
      //     isNormal, errorMsg,
      //   });
      // } else {
      this.search(obj.code, obj.state, obj.appid);
      // }
    });
  }
  render() {
    const { isRecord, isNormal, records, brand, errorMsg, loading, isWeixin } = this.state;
    return (
      <div className='col-t back-color2'>
        {isRecord ?
          <div>
            <Image src={`https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/AntiFake/fw-${brand}-quality.jpg`} className='margin-b10' preview={false} />
            {
              isWeixin === 'True' ?
                <div className='row-l w100 margin-b10'>
                  <div className='w150 tc'>客户</div>
                  <div className='w220 tc'>访问时间</div>
                </div>
                :
                <div className='row-l w100 margin-b10'>
                  <div className='w150 tc'>访问时间</div>
                  <div className='w220 tc'>地址</div>
                </div>
            }
            <div className='w100 visited'>
              {
                records.map((item, index) => {
                  return (
                    <div className='row-l w100 margin-b10' key={index}>
                      <div className='w150 tc'>{isWeixin === 'True' ? item.nickname : item.time}</div>
                      <div className='w220 tc'>{isWeixin === 'True' ? item.time : item.address}</div>
                    </div>
                  );
                })
              }
            </div>
            <div className='w100 row'>
              <Button onClick={this.toResult} className={`${styles['btn']}`}>返回</Button>
            </div>
          </div>
          :
          <div>
            {
              isNormal ?
                <div>
                  <Image src={`https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/AntiFake/fw-${brand}-quality.jpg`} className='margin-b10' preview={false} />
                  {
                    records.length !== 0 &&
                    <div className='row-l w100'>
                      <div className={`${styles['left']} col-t vertical-lt w100`}>
                        <div className='color5'>首次查询时间</div>
                        <div className='margin-b10 bold'>{records[0].time}</div>
                        <div className='color5'>首次查询地区</div>
                        <div className='w220 margin-b10 ellipsis-line bold'>{records[0].address}</div>
                        <div className='margin-b10 color5'>查询次数</div>
                        <div className='row-l margin-b10' onClick={this.getRecord}>
                          <div className={`${styles['count']} fz18 color1`}>第 {records.length} 次</div>
                          <div> ☜查看扫码记录</div>
                        </div>
                        {
                          records.length > 5 &&
                          <div className='color6 bold'>（该码已被扫描多次，谨防假冒）</div>
                        }
                      </div>
                    </div>
                  }
                  <div className='w100 row'>
                    <Button onClick={this.reset} className={`${styles['btn']} margin-t20`}>重新查询</Button>
                  </div>
                </div>
                :
                <div className='col w100'>
                  {
                    errorMsg === '不存在' &&
                    <Image src={`https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/AntiFake/fw-${brand}-none.jpg`} preview={false} className='margin-b10' />
                  }
                  {
                    errorMsg === '未授权' &&
                    <Image src={`https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/AntiFake/fw-${brand}-nopermiss.jpg`} preview={false} className='margin-b10' />
                  }
                  <div className='w100 row'>
                    <Button onClick={this.reset} className={`${styles['btn']} margin-t20`}>重新查询</Button>
                  </div>
                </div>
            }
          </div>
        }
        <Spin spinning={loading} size='large' />
      </div>
    );
  }
  search = async (code, state, appid) => {
    // if (!code) {
    //   this.setState({
    //     isNormal: false,
    //     errorMsg: '未授权',
    //     loading: false,
    //   });
    //   return;
    // }
    const { isWeixin } = this.state;

    let url = window.location.href;
    let data = {
      code: code,
      state: state,
      appid: appid,
      url: url,
    };
    let res = await getResult(data);
    if (res.status === 200) {
      //防伪码存在
      this.setState({
        attach: res.data.attach,
        isNormal: true,
      });
      if (isWeixin === 'False') {
        //无公众号
        this.gerRecords(res.data.record, res.data.signature, null, null);
      } else {
        //有公众号
        if (appid) {
          //微信授权
          this.getAddress(res.data);
        } else {
          //微信不授权
          this.setState({
            loading: false,
          });
        }
      }
      // ki9rnr
    } else {
      //防伪码不存在
      this.setState({
        isNormal: false,
        errorMsg: '不存在',
        loading: false,
      });
    }
  }

  getRecord = () => {
    this.setState({
      isRecord: true
    });
  }
  toResult = () => {
    this.setState({
      isRecord: false
    });
  }
  reset = () => {
    let url = window.location.origin + '/fw?b=' + this.state.brand;
    window.location.href = url;
  }

  getAddress = async (data) => {
    let obj = searchConvertObj(window.location.search);
    wx.config({
      debug: false,
      appId: obj.appid,
      timestamp: data.weixin.timestamp,
      nonceStr: data.weixin.nonceStr,
      signature: data.weixin.signature,
      jsApiList: ['getLocation'],
    });
    var that = this;
    wx.ready(function () {
      wx.getLocation({
        type: 'wgs84',
        success: async (res) => {
          let latitude = res.latitude;
          let longitude = res.longitude;
          that.gerRecords(data.record, data.signature, latitude, longitude);
          // let rsp = await getRecords(data.record, data.signature, latitude, longitude);
          // if (rsp.status === 200) {
          //   that.setState({
          //     isNormal: true,
          //     records: rsp.data,
          //     loading: false,
          //   });
          // }
        },
        cancel: (res) => {
          that.setState({
            isNormal: false,
            errorMsg: '地址未授权',
            loading: false,
          });
        }
      });
    });
  }
  gerRecords = async (record, signature, latitude, longitude) => {
    let data = {
      record: record,
      signature: signature,
      latitude: latitude,
      longitude: longitude,
    };
    let rsp = await getRecords(data);
    if (rsp.status === 200) {
      this.setState({
        isNormal: true,
        records: rsp.data,
        loading: false,
      });
    }
  }
}
export default Result;