import React, { Component } from 'react';
import { Modal, Row, message } from 'antd';
import { getCourier } from '../../services/order';
import dropDown1 from '../../resource/img/dropDown1.png';

const Time = (time) => {
  let arr = time.split(' ');
  return (
    <div>
      {arr.map((aItem, aIndex) => (
        <div key={aIndex}>{aItem}</div>
      ))}
    </div>
  );
};

const deliveryColor = (cItem, cIndex) => {
  if (cIndex === 0) {
    return (
      <div className={'row-l color2 height'}>
        <div className={'left'} >
          {Time(cItem.time)}
        </div>
        <div className={'right'}>
          <div className={'round'} style={{ backgroundColor: 'red' }}></div>
          {cItem.trace}
        </div>
      </div>
    );
  } else {
    return (
      <div className={'row-l color4 height'}>
        <div className={'left'}>
          {Time(cItem.time)}
        </div>
        <div className={'right'}>
          <div className={'round'} style={{ backgroundColor: '#666' }}></div>
          {cItem.trace}
        </div>
      </div>
    );
  }
};

class Orderpress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      deliveryData: []
    };
  }

  render() {
    const { visible, deliveryData } = this.state;
    return (
      <Modal
        visible={visible}
        closable={false}
        onCancel={this.onCancel}
        footer={null}
      >
        <Row>
          {deliveryData.map((Item, Index) => {
            return (
              <div key={Index} style={{ width: '100%' }}>
                {deliveryColor(Item, Index)}
              </div>
            );
          })
          }
        </Row>
        <div>
          <img src={dropDown1} alt={'关闭'} className={'shutDown'} onClick={this.onCancel} />
        </div>
      </Modal>
    );
  }

  onShowModal = async (numbers, companyId) => {
    let res = await getCourier(companyId, numbers);
    if (res.status === 200) {
      let data = res.data;
      this.setState({
        deliveryData: data.reverse(),
      });
      if (data.length === 0) {
        message.error('暂无该快递物流信息');

      } else {
        this.setState({
          visible: true
        });
      }
    }
  }

  onCancel = () => {
    this.setState({
      visible: false,
    });
  };
}

export default Orderpress;